import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`My background`}</h2>
    <p>{`I grew up between Hong Kong and Mumbai and moved to Silicon Valley at age 10. My education was progressive, globally minded, emphasized social-emotional learning, and accelerated in math and computer science. I started building technology products and studying public markets in high school.`}</p>
    <p><strong parentName="p">{`I dropped out of university at 18 to found `}<a parentName="strong" {...{
          "href": "https://ashu.xyz/makeschool"
        }}>{`Make School`}</a></strong>{` and participate in Y Combinator. Our vision for Make School grew increasingly ambitious over time. We began as a summer program to teach high school students to code. A decade later, we had built a fully-accredited computer science degree that boasted outcomes competitive with MIT and Stanford. We adopted a Public Benefit Corporation structure to  chart a path towards a humane model of capitalism.`}</p>
    <p>{`Through this journey, we raised $40 million in venture and debt capital, reached $10 million annual revenue, and grew the team to 40 people. We advised university presidents, spoke to Senate about higher education, taught courses at Stanford and MIT, and enabled thousands of students to begin careers at companies like Apple, Microsoft, Google, and top YC companies. COVID had a profound impact on higher ed, leading us to merge with our partner university Dominican University of California.`}</p>
    <p><strong parentName="p">{`I then spent a year as a visiting partner at Y Combinator`}</strong>{`, advising startups across geographies and industries. I studied what makes founders and teams successful, both within the YC network and historically with Fortune 100 companies. I'm now shifting my entreprenuerial focus from education into arts and culture, while supporting tech entrepreneurs across industries.`}</p>
    <p>{`My experiences give me a unique perspective on what makes top caliber teams succeed and fail, both from an analytical/systems lens and from a human/emotional lens. `}<strong parentName="p">{`I've personally coached hundreds of people and designed curriculum/systems to coach thousands.`}</strong>{` I have a deep expertise in education and I'm a technologist and builder at heart.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      